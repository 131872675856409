<template>
  <el-dialog
    :title="activeDialogKey == 'generateKey' ? '生成密钥' : '绑定密钥'"
    :visible.sync="visible"
    @close="switchDialogVisible(false)"
    :width="activeDialogKey == 'generateKey' ? '40%' : '68%'"
  >
    <el-form
      :model="generateKeyForm"
      :rules="rules"
      ref="generateKeyForm"
      label-width="100px"
      class="generateKeyForm"
      v-if="activeDialogKey == 'generateKey'"
    >
      <el-form-item label="时间" prop="timeRange">
        <el-date-picker
          v-model="generateKeyForm.timeRange"
          type="datetimerange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
        >
        </el-date-picker>
      </el-form-item>

      <el-form-item label="用途" prop="use_scenes">
        <el-input
          v-model="generateKeyForm.use_scenes"
          placeholder="请输入用途"
        ></el-input>
      </el-form-item>
      <el-form-item label="个数" prop="times">
        <el-input-number
          v-model="generateKeyForm.times"
          controls-position="right"
          :min="1"
        />
      </el-form-item>
      <el-form-item label="级别" prop="level">
        <el-radio-group v-model="generateKeyForm.level">
          <el-radio
            v-for="l in projectLevels"
            :label="l.project_code"
            :key="l.id"
            >{{ l.project_type }}</el-radio
          >
        </el-radio-group>
      </el-form-item>
    </el-form>

    <div class="bindUser" v-else>
      <span class="label">昵称：</span>
      <el-input
        placeholder="请输入昵称"
        v-model="searchName"
        clearable
        width="400"
      >
      </el-input>
      <span class="label">手机号：</span>
      <el-input
        placeholder="请输入手机号"
        v-model="searchPhoneNumber"
        clearable
        width="400"
      >
      </el-input>
      <el-button
        type="primary"
        @click="
          getAllUsers({ nick_name: searchName, phone: searchPhoneNumber })
        "
        >搜索</el-button
      >

      <div class="" v-if="allUsersData.length > 0">
        <el-radio-group v-model="checkedUser">
          <el-radio :label="u.id" v-for="(u, i) in allUsersData" :key="u.id">
            <span class="nickName">{{ u.cn_name || `无名称${i}` }}</span>
            <span class="phone">{{ u.phone }}</span>
          </el-radio>
        </el-radio-group>
      </div>
      <el-empty description="暂无数据" v-else></el-empty>
    </div>
    <span slot="footer">
      <el-button @click="switchDialogVisible(false)">取 消</el-button>
      <el-button type="primary" @click="onConfirm">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
export default {
  props: {
    visible: Boolean,
    switchDialogVisible: Function,
    activeDialogKey: String,
    refresh: Function,
    secretKeyId: String,
  },
  data() {
    return {
      generateKeyForm: {
        timeRange: [new Date(), new Date()],
        use_scenes: "",
        times: 1,
        level: "",
      },
      rules: {
        timeRange: [
          {
            required: true,
            message: "请输入时间",
            trigger: "blur",
          },
        ],
        use_scenes: [
          {
            required: true,
            message: "请输入用途",
            trigger: "blur",
          },
        ],
        times: [
          {
            required: true,
            message: "请输入次数",
            trigger: "blur",
          },
        ],
      },
      allUsersData: [],
      checkedUser: "",
      searchPhoneNumber: "",
      searchName: "",
      projectLevels: [],
    };
  },
  mounted() {
    this.getAllUsers();
    this.getProjectLevelsData();
  },
  watch: {
    projectLevels(v) {
      this.generateKeyForm.level = v.length && v[0].project_code;
    },
  },
  methods: {
    getProjectLevelsData() {
      this.$request.getProjectLevelsList({}).then((res) => {
        if (res.status != "success") {
          this.$message.error(res.msg);
          return;
        }

        this.projectLevels = res?.data?.result || [];
      });
    },
    getAllUsers(params) {
      this.$request.queryAllUsers({ select_param: params }).then((res) => {
        if (res.status != "success") {
          this.$message.error(res.msg);
          return;
        }
        this.allUsersData = res?.data?.result || [];
      });
    },
    onConfirm() {
      if (this.activeDialogKey == "generateKey") {
        this.generateKey();
      } else {
        this.bindSecret();
      }
    },

    bindSecret() {
      this.$request
        .bindSecretKey({
          id: this.secretKeyId,
          bind_user_id: this.checkedUser,
        })
        .then((res) => {
          if (res.status != "success") {
            this.$message.error(res.msg);
            return;
          }
          this.$message({
            showClose: true,
            message: "绑定成功",
            type: "success",
          });
          this.refresh(true);
        });
    },
    generateKey() {
      this.$refs.generateKeyForm.validate((valid) => {
        if (!valid) {
          return false;
        }
        const { use_scenes, timeRange, level, times } = this.generateKeyForm;
        this.$request
          .createSecretKey({
            start_time: timeRange[0].valueOf(),
            end_time: timeRange[1].valueOf(),
            use_scenes,
            level,
            num: times,
          })
          .then((res) => {
            if (res.status != "success") {
              this.$message.error(res.msg);
              return;
            }
            this.$message({
              showClose: true,
              message: "生成成功",
              type: "success",
            });
            this.refresh();
          });
      });
    },
  },
};
</script>

<style lang='scss'>
.generateKeyForm {
  .el-input--small,
  .el-input-number--small {
    width: 400px !important;
  }
}
.bindUser {
  max-height: 400px;
  overflow: auto;
  padding: 0 24px;
  .label {
    margin-right: 6px;
  }
  .el-input--small {
    width: 300px;
    margin: 0 12px 22px 0;
  }
  .nickName {
    width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: inline-block;
    vertical-align: middle;
  }
  .phone {
    margin-left: 38px;
  }
  .el-radio {
    display: block;
    line-height: 34px;
  }
  .el-empty__image {
    width: 80px !important;
  }
  .el-empty {
    padding: 12px 0 !important;
  }
}
</style>